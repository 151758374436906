import React, {Fragment, Component} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import './Dashboard.css';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { get, put, del } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

Amplify.configure(amplifyconfig);


const firebaseConfig = {
  apiKey: 'AIzaSyCfPhLIVq1snRWD9DbAi4l5Y7JFUv-cLJ0',
  authDomain: 'auth.amiccio.com',
  projectId: 'amiccio-f3188',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const providerGG = new GoogleAuthProvider();
const providerFB = new FacebookAuthProvider();

export default class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      nav: 0,
      loading: false,
      userEmail: undefined,
      user: {},
      accountEmail: undefined,
      eventsEmail: undefined,
      phoneNumber: undefined,
      firstName: undefined,
      lastName: undefined,
      gender: undefined,
      photo: undefined,
      photoFile: undefined,
      eventsList: [],
      
      editingSettings: false,
      editingProfile: false,

      eventAuth: false,
      activeAuth: undefined,
      activeCode: undefined,
      displaySpecificEvent: false,

      activeGenderBar: undefined,
      activeEvent: undefined,
      activeEventStatus: 1,
      menAttendees: [],
      womenAttendees: [],
      userLikes: [],

      viewMatches: false,
      matchesList: [],

      createTitle: undefined,
      createTime: undefined,
      createCode: undefined,
      dateValue: undefined,
      createdEventStatus: 'No event created yet this session'
    }
  }

  setDate = (value) => {
    this.setState({dateValue: value})
  }

  getCurrentUser = async () => {
    const user = auth.currentUser;   
    if (user) {
      this.setState({userEmail: user.email})
      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
        const {body} = await getOp.response
        const getResponse = await body.json()
        this.setState({user: getResponse})
        this.setState({
          accountEmail: getResponse.EmailID,
          eventsEmail: getResponse.EventsEmails ? getResponse.EventsEmails[0] : undefined,
          firstName: getResponse.FirstName,
          lastName: getResponse.LastName,
          gender: getResponse.Gender,
          photo: getResponse.Photos[0],
          phoneNumber: getResponse.Phone
        })

        if (getResponse.Gender === 'Man') {
          this.setState({activeGenderBar: 1})
        } else if (getResponse.Gender === 'Woman') {
          this.setState({activeGenderBar: 0})
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      this.props.logout()
    }
  }

  saveSettings = async () => {
    try {
      const userToUpdate = this.state.user
      userToUpdate.EventsEmails = [this.state.eventsEmail]
      userToUpdate.Phone = [this.state.phoneNumber]
      const putOp = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: userToUpdate
        }
      })
      const putResponse = await putOp.response
      this.setState({editingSettings: false})
    } catch (error) {
      console.log(error)
    }
  }

  saveProfile = async () => {
    if (this.state.photoFile === undefined) {
      try {
        const userToUpdate = this.state.user
        userToUpdate.FirstName = this.state.firstName
        userToUpdate.LastName = this.state.lastName
        userToUpdate.Gender = this.state.gender
        const putOp = put({
          apiName: 'usersWebAppAPI',
          path: '/users',
          options: {
            body: userToUpdate
          }
        })
        const putResponse = await putOp.response
        this.setState({editingProfile: false})
        this.setState({loading: false})
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        this.setState({loading: true})
        const filename = new Date().getTime()
        const result = await uploadData({
          key: filename + '.jpeg',
          data: this.state.photoFile,
          options: {
            accessLevel: 'guest',
            contentType: 'image/jpeg'
          }
        }).result
  
        this.setState({photo: 'https://d10sm22jqs00ve.cloudfront.net/public/' + result.key}, async()=>{
          const userToUpdate = this.state.user
          userToUpdate.FirstName = this.state.firstName
          userToUpdate.LastName = this.state.lastName
          userToUpdate.Gender = this.state.gender
          userToUpdate.Photos = [this.state.photo]
          const putOp = put({
            apiName: 'usersWebAppAPI',
            path: '/users',
            options: {
              body: userToUpdate
            }
          })
          const putResponse = await putOp.response
          this.setState({editingProfile: false})
          this.setState({loading: false})
        })
      } catch (error) {
        console.log(error)
        this.setState({loading: false})
      }
    }
  }

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      this.setState({
        photo: URL.createObjectURL(img),
        photoFile: img
      })
    }
  }

  getEventsList = async () => {
    try {
      const getOp = get({apiName: 'eventsWebAppAPI', path: '/events'})
      const {body} = await getOp.response
      const getResponse = await body.json()
      getResponse.sort((a,b)=> a.Timestamp - b.Timestamp)
      this.setState({eventsList: getResponse})
    } catch (error) {
      console.log(error)
    }
  }

  registerAttendee = async (eventID) => {
    try {
      //get event and add user to event
      const getOp = get({apiName: 'eventsWebAppAPI', path: '/events/object/' + eventID})
      const {body} = await getOp.response
      const getResponse = await body.json()
      const eventToUpdate = getResponse

      if (eventToUpdate.Attendees.includes(this.state.userEmail)) {
        //do nothing
        if (this.state.user.Gender === 'Man') {
          this.setState({activeGenderBar: 1})
        } else if (this.state.user.Gender === 'Woman') {
          this.setState({activeGenderBar: 0})
        }
      } else {
        eventToUpdate.Attendees.push(this.state.userEmail)
        eventToUpdate.AttendeesLikes.push({attendee: this.state.userEmail, gender: this.state.user.Gender, likes: []})
        if (this.state.user.Gender === 'Man') {
          eventToUpdate.Men.push(this.state.userEmail)
          this.setState({activeGenderBar: 1})
        } else if (this.state.user.Gender === 'Woman') {
          eventToUpdate.Women.push(this.state.userEmail)
          this.setState({activeGenderBar: 0})
        }
        
        //get user and add event to user
        const userToUpdate = this.state.user
        userToUpdate.EventsSignedUp.push(eventToUpdate.EventID)
        const putOpUser = put({
          apiName: 'usersWebAppAPI',
          path: '/users',
          options: {
            body: userToUpdate
          }
        })
        const putResponseUser = await putOpUser.response
      }

      const putOp = put({
        apiName: 'eventsWebAppAPI',
        path: '/events',
        options: {
          body: eventToUpdate
        }
      })
      const putResponse = await putOp.response

      this.setState({userLikes: eventToUpdate.AttendeesLikes.filter((item)=> {return item.attendee === this.state.userEmail})})
      this.setState({activeEvent: eventToUpdate})
    } catch (error) {
      console.log(error)
    }
  }

  loadActiveEvent = async (eventID) => {
    try {
      const getOp = get({apiName: 'eventsWebAppAPI', path: '/events/object/' + eventID})
      const {body} = await getOp.response
      const getResponse = await body.json()
      this.setState({activeEvent: getResponse})
      this.setState({userLikes: getResponse.AttendeesLikes.filter((item)=> {return item.attendee === this.state.userEmail})})
      this.loadActiveEvent2()
    } catch (error) {
      console.log(error)
    }
  }

  loadActiveEvent2 = () => {
    try {
      let menAttendees = []
      let womenAttendees = []
      this.setState({menAttendees: []})
      this.setState({womenAttendees: []})
      
      this.state.activeEvent.Attendees.map(async(attendee)=>{
        try {
          const getAtd = get({apiName: 'usersWebAppAPI', path: '/users/object/' + attendee})
          const {body} = await getAtd.response
          const getResponseAtd = await body.json()
          if (getResponseAtd.Gender === 'Man') {
            menAttendees.push(getResponseAtd)
            this.setState({menAttendees: menAttendees})
          } else if (getResponseAtd.Gender === 'Woman') {
            womenAttendees.push(getResponseAtd)
            this.setState({womenAttendees: womenAttendees})
          }
        } catch (error) {
          console.log(error)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  addToLikes = async (attendee) => {
    const userLikes = this.state.userLikes
    if (userLikes[0].likes.includes(attendee) === false) {
      try {
        userLikes[0].likes.push(attendee.EmailID)
        this.setState({userLikes: userLikes})

        const getOp = get({apiName: 'eventsWebAppAPI', path: '/events/object/' + this.state.activeEvent.EventID})
        const {body} = await getOp.response
        const getResponse = await body.json()
        const eventToUpdate = getResponse
        const index = eventToUpdate.AttendeesLikes.findIndex(item => item.attendee === this.state.userEmail)
        eventToUpdate.AttendeesLikes[index].likes = this.state.userLikes[0].likes

        const putOp = put({
          apiName: 'eventsWebAppAPI',
          path: '/events',
          options: {
            body: eventToUpdate
          }
        })
        const putResponse = await putOp.response
      } catch (error) {
        console.log(error)
      }
      
    }
  }

  removeFromLikes = async (attendee) => {
    const userLikes = this.state.userLikes
    if (userLikes[0].likes.includes(attendee.EmailID) === true) {
      try {
        const index = userLikes[0].likes.indexOf(attendee.EmailID)
        userLikes[0].likes.splice(index, 1)
        this.setState({userLikes: userLikes})

        const getOp = get({apiName: 'eventsWebAppAPI', path: '/events/object/' + this.state.activeEvent.EventID})
        const {body} = await getOp.response
        const getResponse = await body.json()
        const eventToUpdate = getResponse
        const indexDB = eventToUpdate.AttendeesLikes.findIndex(item => item.attendee === this.state.userEmail)
        eventToUpdate.AttendeesLikes[indexDB].likes = this.state.userLikes[0].likes

        const putOp = put({
          apiName: 'eventsWebAppAPI',
          path: '/events',
          options: {
            body: eventToUpdate
          }
        })
        const putResponse = await putOp.response
      } catch (error) {
        console.log(error)
      }
    }
  }

  loadMatches = async () => {
    try {
      const personalLikes = this.state.activeEvent.AttendeesLikes.filter((item)=> {return item.attendee === this.state.userEmail})
      const personalLikesList = personalLikes[0] ? personalLikes[0].likes : []
      let matchesList = await Promise.all(
        personalLikesList.map((likedPerson)=>{
          const otherPersonsLikes = this.state.activeEvent.AttendeesLikes.filter((item)=> {return item.attendee === likedPerson})
          const otherPersonsLikesList = otherPersonsLikes[0] ? otherPersonsLikes[0].likes : []
          if (otherPersonsLikesList.includes(this.state.userEmail) === true) {
            return likedPerson
          } else {
            return false
          }
        })
      )
      matchesList = matchesList.filter((item)=> {return item !== false})

      const fullMatchesInfo = await Promise.all(
        matchesList.map(async(matchID)=>{
          try {
            const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + matchID})
            const {body} = await getOp.response
            const getResponse = await body.json()
            return getResponse
          } catch (error) {
            console.log(error)
          }
        })
      )

      this.setState({matchesList: fullMatchesInfo})
    } catch (error) {
      console.log(error)
    }
  }

  createEvent = async () => {
    if (this.state.createCode && this.state.createTitle && this.state.dateValue) {
      try {
        const date1 = new Date(this.state.dateValue).getTime()

        const eventToUpdate = {
          EventID: date1.toString(),
          Attendees: [],
          AttendeesLikes: [],
          Code: this.state.createCode,
          Matches: [],
          Men: [],
          Timestamp: date1,
          Title: this.state.createTitle,
          Women: []
        }
  
        const putOp = put({
          apiName: 'eventsWebAppAPI',
          path: '/events',
          options: {
            body: eventToUpdate
          }
        })
        const putResponse = await putOp.response
        this.setState({createdEventStatus: 'Just created an event: ' + eventToUpdate.Title + ' ' + new Date(eventToUpdate.Timestamp)})
      } catch (error) {
        console.log(error)
      }
    }
  }



  componentDidMount() {
    setTimeout(()=> {
      this.getCurrentUser()
    }, 800)
    this.getEventsList()
  }
  
  render(){
    const styles = {
      logout: {
        color: 'white',
        backgroundColor: '#000000',
        width: '120px',
        padding: '12px',
        borderRadius: '20px',
        margin: '20px 0px',
        cursor: 'pointer',
        fontFamily: 'arial',
        justifySelf: 'left',
        marginBottom: '100px'
      },
      navContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        zIndex: '10',
        backgroundColor: 'white',
        borderTop: 'solid 1px black'
      },
      navButton1: {
        margin: '15px',
        textDecoration: 'underline',
        cursor: 'pointer',
        width: '100px'
      },
      navButton2: {
        margin: '15px',
        color: '#ffffff',
        backgroundColor: '#000000',
        borderRadius: '30px',
        cursor: 'pointer',
        width: '100px'
      },

      //-----------------------------------------------------------------------------

      settingsContainer: {
        display: this.state.nav === 2 ? 'grid' : 'none',
      },
      title: {
        color: '#ffcc00',
        fontSize: '40px',
        marginTop: '20px',
        justifySelf: 'left',
      },
      settingsTitleDiv: {
        textAlign: 'left',
        fontWeight: 'bold',
        marginBottom: '10px',
        marginTop: '30px'
      },
      settingsDiv: {
        textAlign: 'left',
        marginBottom: '10px',
        display: this.state.editingSettings === false ? true : 'none'
      },
      settingsInput: {
        marginBottom: '10px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        minWidth: '240px',
        justifySelf: 'left',
        display: this.state.editingSettings === false ? 'none' : true
      },
      editSettingsButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? true : 'none'
      },
      saveSettingsButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? 'none' : true
      },

      adminContainer: {
        justifySelf: 'left',
        justifyContent: 'left',
        display: this.state.userEmail === 'yelah2007@gmail.com' ? true : 'none',
        marginBottom: '100px'
      },
      adminTitle: {
        textAlign: 'left',
        fontWeight: 'bold'
      },
      createEventButton: {
        backgroundColor: 'purple',
        color: 'white',
        padding: '15px',
        borderRadius: '20px',
        cursor: 'pointer',
        marginTop: '40px',
        marginBottom: '40px',
        width: '120px'
      },
      createDiv: {
        textAlign: 'left',
        marginBottom: '10px',
        maxWidth: '240px',
      },
      createInput: {
        marginBottom: '10px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        minWidth: '240px',
        justifySelf: 'left',
      },

      //-----------------------------------------------------------------------------

      profileContainer: {
        display: this.state.nav === 1 ? 'grid' : 'none'
      },
      profileDiv: {
        textAlign: 'left',
        marginBottom: '10px',
        display: this.state.editingProfile === false ? true : 'none'
      },
      profileInput: {
        marginBottom: '10px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        minWidth: '240px',
        justifySelf: 'left',
        display: this.state.editingProfile === false ? 'none' : true
      },
      editProfileButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === false ? true : 'none'
      },
      saveProfileButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === false ? 'none' : true
      },
      loaderImg: {
        display: this.state.loading === true ? true : 'none',
        justifySelf: 'left',
      },
      activeGender: {
        color: 'white',
        backgroundColor: 'purple',
        margin: '5px',
        marginLeft: '0px',
        padding: '2px 10px',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === true ? true : 'none'
      },
      inactiveGender: {
        margin: '5px',
        marginLeft: '0px',
        padding: '2px 10px',
        border: 'solid 1px black',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === true ? true : 'none'
      },

      //-----------------------------------------------------------------------------

      eventsContainer: {
        display: this.state.nav === 0 ? 'grid' : 'none',
        marginTop: '40px'
      },
      eventsTitle: {
        color: '#ffcc00',
        fontSize: '40px',
        marginTop: '20px',
        marginBottom: '20px'
      },
      eventsBox: {
        margin: '30px auto',
        backgroundColor: 'black',
        color: 'white',
        padding: '10px',
        borderRadius: '10px',
        width: '300px',
        cursor: 'pointer'
      },
      eventsListBox: {
        display: this.state.displaySpecificEvent === false ? true : 'none'
      },
      authInput: {
        marginBottom: '20px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        width: '240px',
        textAlign: 'center'
      },
      submitEventAuth: {
        margin: '10px auto',
        marginBottom: '80px',
        padding: '10px',
        borderRadius: '10px',
        color: 'white',
        backgroundColor: 'purple',
        width: '100px',
        cursor: 'pointer'
      },
      authProfile: {
        textAlign: 'left',
        margin: '10px'
      },


      
      specificEvent: {
        display: this.state.displaySpecificEvent === true && this.state.nav === 0 ? true : 'none'
      },
      genderBar: {
        margin: '10px auto',
        justifyContent: 'center',
        display: this.state.displaySpecificEvent === true ? 'flex' : 'none'
      },
      activeGenderBar: {
        color: 'white',
        backgroundColor: 'purple',
        border: 'solid 2px purple',
        padding: '8px',
        width: '140px',
        cursor: 'pointer'
      },
      inactiveGenderBar: {
        color: 'purple',
        border: 'solid 2px purple',
        padding: '8px',
        width: '140px',
        cursor: 'pointer'
      },
      backtoList: {
        color: 'white',
        backgroundColor: 'purple',
        padding: '10px',
        borderRadius: '10px',
        width: '160px',
        margin: '40px auto',
        marginTop: '10px',
        cursor: 'pointer',
        display: this.state.displaySpecificEvent === true ? true : 'none'
      },
      
      activeChoice: {
        backgroundColor: 'black',
        color: 'white',
        width: '60px',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
        margin: '20px 10px',
      },
      inactiveChoice: {
        border: 'solid 1px black',
        width: '60px',
        padding: '10px',
        borderRadius: '10px',
        cursor: 'pointer',
        margin: '20px 10px'
      }
    }
  
    const eventsList = this.state.eventsList.map((event,i)=>{
      if (event && Date.now() < event.Timestamp && this.state.activeEventStatus === 1) {
        return (
          <div key={i}>
            <div style={styles.eventsBox} onClick={()=> this.setState({eventAuth: !this.state.eventAuth, activeAuth: event.EventID})}>{event.Title}</div>
            <div style={{display: this.state.eventAuth === true && this.state.activeAuth === event.EventID ? true : 'none'}} className='eventAuthBox'>
            <div style={{margin: '20px auto', width: '250px', borderRadius: '20px', backgroundColor: '#ECF0F1', padding: '20px'}}>
              <div style={styles.authProfile}>Email: {this.state.user.EmailID ? this.state.user.EventsEmails[0] : undefined}</div>
              <div style={styles.authProfile}>Phone: {this.state.user.Phone ? this.state.user.Phone : undefined}</div>
              <div style={styles.authProfile}>Name: {this.state.user.EmailID ? this.state.user.FirstName : undefined} {this.state.user.EmailID ? this.state.user.LastName : undefined}</div>
              <div style={styles.authProfile}>Ticket Type: {this.state.user.Gender ? this.state.user.Gender : undefined}</div>
            </div>
            <input style={styles.authInput} defaultValue={'Enter event code'} value={this.state.activeCode} onChange={(e)=> this.setState({activeCode: e.target.value.toLowerCase()})}/>
            <div style={styles.submitEventAuth} onClick={()=> {
              if (this.state.activeCode === event.Code) {
                if (this.state.user.EmailID && this.state.user.EventsEmails[0] && this.state.user.FirstName && this.state.user.Gender) {
                  this.setState({displaySpecificEvent: true})
                  this.setState({activeEvent: event})
                  this.registerAttendee(event.EventID)
                  this.loadActiveEvent(event.EventID)
                } else {
                  alert('Incomplete profile! Please fill out your basic info in the profile and settings tabs')
                }           
              } else {
                alert('Wrong code! Try again')
              }
            }}>Submit</div>
            </div>
          </div>
        )
      } else if (event && Date.now() > event.Timestamp && this.state.activeEventStatus === 0) {
        return (
          <div key={i}>
            <div style={styles.eventsBox} onClick={()=> this.setState({eventAuth: !this.state.eventAuth, activeAuth: event.EventID})}>{event.Title}</div>
            <div style={{display: this.state.eventAuth === true && this.state.activeAuth === event.EventID ? true : 'none'}} className='eventAuthBox'>
            <div style={{margin: '20px auto', width: '250px', borderRadius: '20px', backgroundColor: '#ECF0F1', padding: '20px'}}>
              <div style={styles.authProfile}>Email: {this.state.user.EmailID ? this.state.user.EventsEmails[0] : undefined}</div>
              <div style={styles.authProfile}>Phone: {this.state.user.Phone ? this.state.user.Phone : undefined}</div>
              <div style={styles.authProfile}>Name: {this.state.user.EmailID ? this.state.user.FirstName : undefined} {this.state.user.EmailID ? this.state.user.LastName : undefined}</div>
              <div style={styles.authProfile}>Ticket Type: {this.state.user.Gender ? this.state.user.Gender : undefined}</div>
            </div>
            <input style={styles.authInput} defaultValue={'Enter event code'} value={this.state.activeCode} onChange={(e)=> this.setState({activeCode: e.target.value.toLowerCase()})}/>
            <div style={styles.submitEventAuth} onClick={()=> {
              if (this.state.activeCode === event.Code) {
                if (this.state.user.EmailID && this.state.user.EventsEmails[0] && this.state.user.FirstName && this.state.user.Gender) {
                  this.setState({displaySpecificEvent: true})
                  this.setState({activeEvent: event})
                  this.registerAttendee(event.EventID)
                  this.loadActiveEvent(event.EventID)
                } else {
                  alert('Incomplete profile! Please fill out your basic info in the profile and settings tabs')
                }           
              } else {
                alert('Wrong code! Try again')
              }
            }}>Submit</div>
            </div>
          </div>
        )
      }
    })


    const menAttendees = this.state.menAttendees.map((attendee, i)=>{
      if (attendee && this.state.activeGenderBar === 0 && this.state.userLikes[0]) {
        return (
          <div key={i}>
            <img src={attendee.Photos[0] ? attendee.Photos[0] : 'https://d10sm22jqs00ve.cloudfront.net/public/user.png'} width="100px" height="100px" style={{borderRadius: '50%', marginTop: '20px', marginBottom: '10px',  filter: this.state.activeEvent && Date.now() < this.state.activeEvent.Timestamp ? 'blur(5px)' : 'blur(0px)'}}/>
            <div>{attendee.FirstName} {attendee.LastName}</div>
            <div style={{display: this.state.activeEvent.Timestamp + 24*60*60*1000 > Date.now() ? 'flex' : 'none', justifyContent: 'center'}}>
              <div style={this.state.userLikes[0].likes.includes(attendee.EmailID) === true ? styles.activeChoice : styles.inactiveChoice} onClick={()=> this.addToLikes(attendee)}>Yes</div>
              <div style={this.state.userLikes[0].likes.includes(attendee.EmailID) === false ? styles.activeChoice : styles.inactiveChoice} onClick={()=> this.removeFromLikes(attendee)}>No</div>
            </div>
          </div>
        )
      }
    })
    
 
    const womenAttendees = this.state.womenAttendees.map((attendee, i)=>{
      if (attendee && this.state.activeGenderBar === 1 && this.state.userLikes[0]) {
        return (
          <div key={i}>
            <img src={attendee.Photos[0] ? attendee.Photos[0] : 'https://d10sm22jqs00ve.cloudfront.net/public/user.png'} width="100px" height="100px" style={{borderRadius: '50%', marginTop: '20px', marginBottom: '10px', filter: this.state.activeEvent && Date.now() < this.state.activeEvent.Timestamp ? 'blur(5px)' : 'blur(0px)'}}/>
            <div>{attendee.FirstName} {attendee.LastName}</div>
            <div style={{display: this.state.activeEvent.Timestamp + 24*60*60*1000 > Date.now() ? 'flex' : 'none', justifyContent: 'center'}}>
              <div style={this.state.userLikes[0].likes.includes(attendee.EmailID) === true ? styles.activeChoice : styles.inactiveChoice} onClick={()=> this.addToLikes(attendee)}>Yes</div>
              <div style={this.state.userLikes[0].likes.includes(attendee.EmailID) === false ? styles.activeChoice : styles.inactiveChoice} onClick={()=> this.removeFromLikes(attendee)}>No</div>
            </div>
          </div>
        )
      }
    })

    
    const matches = this.state.matchesList.map((match,i)=>{
      if (match && this.state.activeEvent && Date.now() > this.state.activeEvent.Timestamp + 24*60*60*1000) {
        return (
          <div key={i}>
            <img src={match.Photos[0] ? match.Photos[0] : 'https://d10sm22jqs00ve.cloudfront.net/public/user.png'} width="100px" height="100px" style={{marginTop: '20px', marginBottom: '10px', borderRadius: '50%'}}/>
            <div>{match.FirstName} {match.LastName}</div>
            <div>{match.EventsEmails[0]}</div>
            <div>{match.Phone}</div>
          </div>
        )
      }
    })

    

    return (
      <Fragment>

        <div style={styles.settingsContainer} className='mainContainer'>
          <div style={styles.title}>Account Settings</div>
          <div style={styles.settingsTitleDiv}>Account Login Email:</div>
          <div style={{textAlign: 'left', marginBottom: '10px'}}>{this.state.accountEmail}</div>
          <div style={styles.settingsTitleDiv}>Email Visible To Matches:</div>
          <div style={styles.settingsDiv}>{this.state.eventsEmail ? this.state.eventsEmail : 'Not Set'}</div>
          <input style={styles.settingsInput} defaultValue={this.state.eventsEmail ? this.state.eventsEmail : 'Not Set'} value={this.state.eventsEmail} onChange={(e)=> this.setState({eventsEmail: e.target.value})}/>

          <div style={styles.settingsTitleDiv}>Phone Number:</div>
          <div style={styles.settingsDiv}>{this.state.phoneNumber ? this.state.phoneNumber : 'Not Set'}</div>
          <input style={styles.settingsInput} defaultValue={this.state.phoneNumber ? this.state.phoneNumber : 'Not Set'} value={this.state.phoneNumber} onChange={(e)=> this.setState({phoneNumber: e.target.value})}/>

          <div style={styles.editSettingsButton} onClick={()=> this.setState({editingSettings: true})}>Edit</div>
          <div style={styles.saveSettingsButton} onClick={()=> this.saveSettings()}>Save</div>         
          <div style={styles.logout} onClick={()=> this.props.logout()}>Logout</div>

          <div style={styles.adminContainer}>
            <div style={styles.adminTitle}>Event Creation Admin</div>

            <div style={styles.settingsTitleDiv}>Event Date & Time:</div>
            <div style={{maxWidth: '240px', textAlign: 'left', marginBottom: '20px'}}>{new Date(this.state.dateValue).toString()}</div>
            <DateTimePicker onChange={(value)=> this.setDate(value)} value={this.state.dateValue} 
              amPmAriaLabel="Select AM/PM"
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              hourAriaLabel="Hour"
              minuteAriaLabel="Minute"
              monthAriaLabel="Month"
              yearAriaLabel="Year"
              maxDetail="minute"
              disableClock={true}
            />

            <div style={styles.settingsTitleDiv}>Event Title:</div>
            <div style={styles.createDiv}>{this.state.createTitle ? this.state.createTitle : 'Not Set'}</div>
            <input style={styles.createInput} defaultValue={this.state.createTitle ? this.state.createTitle : 'Not Set'} value={this.state.createTitle} onChange={(e)=> this.setState({createTitle: e.target.value})}/>

            <div style={styles.settingsTitleDiv}>Event Code:</div>
            <div style={styles.createDiv}>{this.state.createCode ? this.state.createCode : 'Not Set'}</div>
            <input style={styles.createInput} defaultValue={this.state.createCode ? this.state.createCode : 'Not Set'} value={this.state.createCode} onChange={(e)=> this.setState({createCode: e.target.value})}/>

            <div style={styles.createEventButton} onClick={()=> this.createEvent()}>Create Event</div>

            <div style={styles.createDiv}>{this.state.createdEventStatus}</div>
          </div>
        </div>



        

        <div style={styles.profileContainer} className='mainContainer'>
          <div style={styles.title}>Profile</div>
          <div style={styles.settingsTitleDiv}>First Name:</div>
          <div style={styles.profileDiv}>{this.state.firstName ? this.state.firstName : 'Not Set'}</div>
          <input style={styles.profileInput} defaultValue={this.state.firstName ? this.state.firstName : 'Not Set'} value={this.state.firstName} onChange={(e)=> this.setState({firstName: e.target.value})}/>

          <div style={styles.settingsTitleDiv}>Last Initial:</div>
          <div style={styles.profileDiv}>{this.state.lastName ? this.state.lastName : 'Not Set'}</div>
          <input style={styles.profileInput} defaultValue={this.state.lastName ? this.state.lastName : 'Not Set'} value={this.state.lastName} onChange={(e)=> this.setState({lastName: e.target.value})}/>

          <div style={styles.settingsTitleDiv}>Gender:</div>
          <div style={styles.profileDiv}>{this.state.gender ? this.state.gender : 'Not Set'}</div>
          <div style={this.state.gender === 'Man' ? styles.activeGender : styles.inactiveGender} onClick={()=> this.setState({gender: 'Man'})}>Man</div>
          <div style={this.state.gender === 'Woman' ? styles.activeGender : styles.inactiveGender} onClick={()=> this.setState({gender: 'Woman'})}>Woman</div>
          
          <div style={styles.settingsTitleDiv}>Photo (1x1):</div>          
          <img width="100px" height="100px" src={this.state.photo} style={{marginBottom: '10px'}}/>
          <input type="file" style={{display: this.state.editingProfile === true ? true : 'none'}} onChange={this.onImageChange}/>
          <div style={styles.loaderImg} id='loaderImg'></div>

          <div style={styles.editProfileButton} onClick={()=> this.setState({editingProfile: true})}>Edit</div>
          <div style={styles.saveProfileButton} onClick={()=> this.saveProfile()}>Save</div>
        </div>





        <div style={styles.eventsContainer}>
          <div style={styles.eventsTitle}>Events</div>
          <div style={styles.eventsListBox}>
            <div style={{margin: '10px auto', marginBottom: '40px', justifyContent: 'center', display: 'flex'}}>
              <div style={this.state.activeEventStatus === 1 ? styles.activeGenderBar : styles.inactiveGenderBar} onClick={()=> this.setState({activeEventStatus: 1})}>Upcoming Events</div>
              <div style={this.state.activeEventStatus === 0 ? styles.activeGenderBar : styles.inactiveGenderBar} onClick={()=> this.setState({activeEventStatus: 0})}>Past Events</div>
            </div>
            <div>{eventsList}</div>
            </div>
        </div>

        <div style={styles.specificEvent}>
          <div style={styles.backtoList} onClick={()=> this.setState({displaySpecificEvent: false})}>Back to Events List</div>
          <div style={styles.genderBar}>
            <div style={this.state.viewMatches === false ? styles.activeGenderBar : styles.inactiveGenderBar} onClick={()=> this.setState({viewMatches: false})}>{this.state.activeGenderBar === 1? 'Women Attendees' : 'Men Attendees'}</div>
            <div style={this.state.viewMatches === true ? styles.activeGenderBar : styles.inactiveGenderBar} onClick={()=> {this.setState({viewMatches: true}); this.loadMatches()}}>My Matches</div>
          </div>

          <div style={{margin: '30px auto', padding: '10px', maxWidth: '500px', minWidth: '300px', fontWeight: 'bold'}}>{this.state.activeEvent ? this.state.activeEvent.Title : undefined}</div>

          <div style={{display: this.state.viewMatches === false ? true : 'none', marginBottom: '200px'}}>
            <div style={{margin: '30px auto', padding: '10px', maxWidth: '500px', minWidth: '300px'}}>Indicate which attendees you are interested in. Attendee photos will become visible at the start time of the event. You will have 24 hours from the listed start time of your event, when choices become final!</div>
            <div>{menAttendees}</div>
            <div>{womenAttendees}</div>
          </div>

          <div style={{display: this.state.viewMatches === true ? true : 'none'}}>
          <div style={{margin: '30px auto', padding: '10px', maxWidth: '500px', minWidth: '300px'}}>Matches will show up here if the attendees you said yes to also said yes back! These are finalized and will appear 24 hours after the listed start time of your event, if the attendees you indicated interest in also said yes back.</div>
            <div style={{marginBottom: '200px'}}>{matches}</div>
          </div>          
        </div>
        




        <div style={styles.navContainer} className='navContainer'>
          <div style={this.state.nav === 0 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> this.setState({nav: 0})}>
            <p>Events</p>
          </div>
          <div style={this.state.nav === 1 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> this.setState({nav: 1})}>
            <p>Profile</p>
          </div>
          <div style={this.state.nav === 2 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> this.setState({nav: 2})}>
            <p>Settings</p>
          </div>
        </div>

      </Fragment>
    )
  }
  
}
