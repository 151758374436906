import React, {Fragment, Component} from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import logo from './iconroundmin.png';
import './App.css';
import Dashboard from './Dashboard.js';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { get, put, del } from 'aws-amplify/api';

Amplify.configure(amplifyconfig);

const firebaseConfig = {
  apiKey: 'AIzaSyCfPhLIVq1snRWD9DbAi4l5Y7JFUv-cLJ0',
  authDomain: 'auth.amiccio.com',
  projectId: 'amiccio-f3188',
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const providerGG = new GoogleAuthProvider();
const providerFB = new FacebookAuthProvider();

export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      auth: 0,
      loading: true,
      userEmail: undefined
    }
    this.logout = this.logout.bind(this)
  }

  loginGG = () => {
    signInWithPopup(auth, providerGG)
    .then(async(result) => {
      const user = result.user;
      this.setState({userEmail: user.email})
      this.setState({auth: 1})

      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
        const {body} = await getOp.response
        const getResponse = await body.json()

        //If the response status is false, meaning the fetched user does not yet exist, create a new user to database
        //If the response status is the user email, then user exists, do nothing, and login as usual
        if (getResponse.status === false) {
          try {
            const userToCreate = {EmailID: user.email, EventsEmails: [], Photos: [], Cities: [], EventsSignedUp: []}
            const putOp = put({
              apiName: 'usersWebAppAPI',
              path: '/users',
              options: {
                body: userToCreate
              }
            })
            const putResponse = await putOp.response
          } catch (error) {
            alert('Whoops, an error occurred when creating your profile. Please log out and try again later')
          }
        }
      } catch (error) {
        console.log(error)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  loginFB = () => {
    signInWithPopup(auth, providerFB)
    .then(async(result) => {
      const user = result.user;
      this.setState({userEmail: user.email})
      this.setState({auth: 1})

      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
        const {body} = await getOp.response
        const getResponse = await body.json()

        //If the response status is false, meaning the fetched user does not yet exist, create a new user to database
        //If the response status is the user email, then user exists, do nothing, and login as usual
        if (getResponse.status === false) {
          try {
            const userToCreate = {EmailID: user.email, EventsEmails: [], Photos: [], Cities: [], EventsSignedUp: []}
            const putOp = put({
              apiName: 'usersWebAppAPI',
              path: '/users',
              options: {
                body: userToCreate
              }
            })
            const putResponse = await putOp.response
          } catch (error) {
            alert('Whoops, an error occurred when creating your profile. Please log out and try again later')
          }
        }
      } catch (error) {
        console.log(error)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  logout = () => {
    signOut(auth).then(() => {
      this.setState({auth: 0})
      this.setState({userEmail: undefined})
    }).catch((error) => {
      console.log(error)
    })
  }

  getCurrentUser = () => {
    const auth = getAuth();
    const user = auth.currentUser;   
    if (user) {
      this.setState({userEmail: user.email})
      this.setState({auth: 1})
      this.setState({loading: false})
    } else {
      this.setState({auth: 0})
      this.setState({loading: false})
    }
  }

  componentDidMount() {
    setTimeout(()=> {
      this.getCurrentUser()
    }, 800)
    
  }
  
  render(){
    const styles = {
      app: {
        textAlign: 'center',
        margin: '0px'
      },
      loginPage: {
        display: this.state.auth === 0 ? true : 'none'
      },
      title: {
        color: '#ffcc00',
        fontSize: '44px',
        marginTop: '20px'
      },
      subTitle: {
        color: '#000000',
        fontSize: '18px',
        width: '400px',
        margin: '20px auto',
      },
      loginContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: '40px',
        marginBottom: '260px',
        display: this.state.loading === true ? 'none' : true
      },
      googleLogin: {
        color: 'white',
        backgroundColor: '#be2011',
        textAlign: 'center',
        width: '200px',
        padding: '12px',
        borderRadius: '20px',
        cursor: 'pointer',
        margin: '20px auto',
        fontFamily: 'arial'
      },
      facebookLogin: {
        color: 'white',
        backgroundColor: '#1952ea',
        textAlign: 'center',
        width: '200px',
        padding: '12px',
        borderRadius: '20px',
        cursor: 'pointer',
        margin: '20px auto',
        fontFamily: 'arial',
      },
      logout: {
        color: 'white',
        backgroundColor: '#000000',
        width: '120px',
        padding: '12px',
        borderRadius: '20px',
        margin: '40px auto',
        cursor: 'pointer',
        fontFamily: 'arial',
      },
      logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '100px'
      },
      innerlogo: {
        borderRadius: '50%',
      },
      eventsPageLink: {
        color: '#ffcc00',
        fontSize: '18px',
        position: 'fixed',
        bottom: '60px',
        left: '50%',
        width: '180px',
        textAlign: 'center',
        marginLeft: '-90px'
      },
      loader: {
        display: this.state.loading === true ? true : 'none'
      }
    }
  
    

    return (
      <div style={styles.app}>

        <div style={styles.loginPage}>
          <div style={styles.logoContainer}><img src={logo} width="100" height="100" style={styles.innerlogo}></img></div>
          <div style={styles.title}>Amiccio</div>
          <div style={styles.subTitle} className='subTitleRsp'>Welcome! Sign in to view your profile, events, matches, anonymous feedback, and more features!</div>
          <div style={styles.loginContainer}>
            <div style={styles.googleLogin} onClick={()=> this.loginGG()}>Continue with Google</div>
            <div style={styles.facebookLogin} onClick={()=> this.loginFB()}>Continue with Facebook</div>
          </div>
          <div style={styles.loader} id='loader'></div>
          <div ><a style={styles.eventsPageLink} href='https://www.amiccio.com/Events/' target='blank'>Back to Events Schedule</a></div>
        </div>

        {this.state.auth === 1? <Dashboard logout={()=> this.logout()} /> : undefined}

      </div>
    )
  }
  
}
